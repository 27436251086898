<template>
  <!--Global Actions Template -->
  <n-space v-if="actions && actions != null" class="pb-4" justify="end">
    <span>
      <n-dropdown trigger="click" :options="actions" placement="bottom-end">
        <n-button>{{ t("commons.labels.action") }}</n-button>
      </n-dropdown>
    </span>
  </n-space>
  <n-data-table
    :size="isMobile ? 'small' : 'large'"
    :columns="columns"
    :data="data"
    :pagination="pagination"
    :bordered="false"
    @update:filters="handleUpdateFilter"
  />
</template>

<script>
import { h, defineComponent, computed } from "vue";
import { NButton, NDataTable, NDropdown, NIcon, NSpace } from "naive-ui";
import { titleCase } from "title-case";
import { DotsVertical as ItemMenuIcon } from "@vicons/tabler";
import responsive from "@/mixins/responsive";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};

export default defineComponent({
  name: "TableBeta",
  components: { NDataTable, NSpace, NDropdown, NButton },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {
        [];
      },
    },
    globalActions: { type: Object, required: false, default: null },
    itemsActions: { type: Function, required: false, default: null },
    fieldsToHide: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldsTooLong: {
      type: Array,
      required: false,
      default: () => [],
    },
    filterableFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mixins: [responsive],
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const isMobile = computed(() => store.state.app.isMobile);
    // Headers
    const columnsRef = computed(() => {
      const columns = [];
      if (props.data.length === 0) {
        return columns;
      }
      for (const key of Object.keys(props.data[0]).filter(
        (key) => !props.fieldsToHide.includes(key)
      )) {
        columns.push({
          ...{
            title: titleCase(key),
            key: key,
            sorter: "default",
            width: isMobile.value && props.fieldsTooLong.includes(key) ? 100 : null,
            ellipsis:
              isMobile.value && props.fieldsTooLong.includes(key)
                ? {
                    tooltip: true,
                  }
                : null,
          },
          ...getFilterConfiguration(key),
        });
      }
      if (props.itemsActions) {
        columns.push({
          title: "",
          key: "actions",
          width: 25,
          render(row) {
            return h(
              NDropdown,
              {
                size: "small",
                trigger: "click",
                placement: "bottom-end",
                bordered: "false",
                options: props.itemsActions(row)?.map((action) => {
                  return {
                    label: action.label,
                    key: action.key,
                    icon: action.icon ? renderIcon(action.icon) : null,
                    disabled: action.disabled,
                    props: action.props,
                  };
                }),
              },
              {
                default: () =>
                  h(
                    NButton,
                    {
                      text: true,
                    },
                    {
                      icon: () =>
                        h(NIcon, { class: "pt-1" }, { default: () => h(ItemMenuIcon) }),
                    }
                  ),
              }
            );
          },
        });
      }
      return columns;
    });

    //Filter Builder
    const getFilterConfiguration = (key) => {
      if (props.filterableFields.includes(key)) {
        return {
          filterMultiple: false,
          filterOptionValue: null,
          filterOptions: computed(() => {
            const optionsRaw = props.data.reduce(
              (partial, row) =>
                partial.includes(row[key]) ? partial : [...partial, row[key]],
              []
            );

            const options = optionsRaw.reduce(
              (partial, row) => [...partial, { label: row, value: row }],
              []
            );

            return options;
          }).value,
          filter(value, row) {
            return !!~row[key].indexOf(value.toString());
          },
        };
      }
      return {
        filter: false,
      };
    };
    return {
      t,
      columns: columnsRef,
      actions: computed(() => {
        return props.globalActions?.map((action) => {
          return {
            label: action.label,
            key: action.key,
            icon: action.icon ? renderIcon(action.icon) : null,
            disabled: action.disabled,
            props: action.props,
          };
        });
      }),
      pagination: computed(() => {
        return props.data.length > 0 ? { pageSize: isMobile.value ? 10 : 15 } : false;
      }),
      handleUpdateFilter(filters) {
        for (const key of Object.keys(filters)) {
          columnsRef.value.find((column) => column.key === key).filterOptionValue =
            filters[key];
        }
      },
    };
  },
});
</script>
<style lang="scss" scoped>
.n-data-table .n-data-table-td {
  padding: unset;
}
</style>
